import React from 'react'
import { PageRendererProps } from 'gatsby'
import { SitePageContext } from '@/graphql-types'
import { Title, Date, PostContainer } from '@/styles'
import Layout from '@/components/layout'

interface Props extends PageRendererProps {
    pageContext: SitePageContext;
}

const BlogPostTemplate: React.FunctionComponent<Props> = (props) => {
    const post = props.pageContext.post!
    const { title, description, image, date } = post.frontmatter!
    return (
        <Layout location={props.location} title={title || undefined} description={description || undefined} image={image || undefined} article>
            <Title>{ title }</Title>
            <Date>{ date }</Date>
            <PostContainer dangerouslySetInnerHTML={{ __html: post.html! }}></PostContainer>
        </Layout>
    )
}

export default BlogPostTemplate